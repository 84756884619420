import React from 'react';
import { graphql } from 'gatsby';
import { builder, BuilderComponent } from '@builder.io/react';
import '../../static/css/funnel.css';
import { FunnelProvider } from '../context/FunnelContext';
import loadable from '@loadable/component';
import { useFunnelContext } from '../hooks/useFunnelContext';
import { useProductSelection } from '../hooks/useProductSelection';
import StagingIndicator from '../components/StagingIndicator/StagingIndicator';
import { useViewTracking } from '../hooks/useViewTracking';

const Helmet = loadable(() => import('react-helmet'));

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = 'PetLab Co.';
const defaultDescription = 'Supplies, Treats, Snacks & Chews - Join The Pack';

function SlideTemplate(props) {
  const {
    data: { allBuilderModels }
  } = props;

  const {
    oneSlide: { content: slideContent }
  } = allBuilderModels;

  const context = useFunnelContext(props);

  useViewTracking();
  useProductSelection(context);

  return (
    <>
      <FunnelProvider value={context}>
        <Helmet
          bodyAttributes={{
            class: `petlab-slide`
          }}
          htmlAttributes={{
            lang: 'en'
          }}
        >
          <title>
            {(slideContent && slideContent.data?.title) || defaultTitle}
          </title>
          <meta
            name="description"
            content={
              (slideContent && slideContent.data?.description) ||
              defaultDescription
            }
          />
          <meta name="funnel-id" content={slideContent.id} />
          <meta
            name="product-selector-id"
            content={slideContent.data.products.id}
          />
          <meta
            name="magic-key"
            content="t54cdW3cbU45TWPRtYGUR4fyBnWHe49jWeuqdRStfAvELctRyfyXLmDteF9Cf9jhPsyzDfkcCK5DNU6Pr87HU8tgNKBDDmeHDFQn"
          />
        </Helmet>
        {/** name of the model is landing page, change it if you decided to build*/}
        <BuilderComponent
          model="slide"
          content={slideContent}
          data={{
            ...context
          }}
        ></BuilderComponent>
      </FunnelProvider>
      {process.env.GATSBY_FUNNEL_STAGING && <StagingIndicator />}
    </>
  );
}

export const query = graphql`
  query($funnelId: String) {
    allBuilderModels {
      oneSlide(query: { id: $funnelId }, options: { cachebust: true }) {
        content
        data {
          title
          description
        }
      }
    }

    funnelPageData(funnelId: { eq: $funnelId }) {
      ...FunnelDataFragment
    }
  }
`;

export default SlideTemplate;
